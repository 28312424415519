.my-atropos {
    width: 320px;
    height: 160px;

}
*{
    color:"#000";
}
#dashFooter{
    background-color: unset !important;
}